
import { useMovimentiStore } from "~/store/movimentiStored";
import { useColliStore } from "~/store/colliStored";
import { useNodiStore } from "~/store/nodiStored";
import { useProdottiStoccabiliStore } from "~/store/prodottiStoccabiliStored";
import { useUsciteConsegnaStore } from "~/store/usciteConsegnaStored";
import { useSquadreStore } from "~/store/squadreStored";
import { useOrdiniAcquistoStore } from "~/store/ordiniAcquistoStored";
import { useTicketAssistenzaStore } from "~/store/ticketAssistenzaStored";
import { useAuthStored } from "~/store/authStored";

export default defineNuxtPlugin({
  name: "fetchNecessaryData",
  async setup (nuxtApp) {
    // Every time the route changes (fired on initialization too)
    const authStored = useAuthStored();

    // eslint-disable-next-line no-undef
    const sessionId = useCookie("session_id");
    const token = useCookie("token");
    if (sessionId.value && token.value) {
      // ottengo i ruoli anche dentro il plugin perchè se l'utente aggiorna la pagina, prima vengono eseguiti i plugin e poi i middleware
      if (authStored.ruoli.length === 0) {
        await authStored.getRuoli();
      }
    }
  }
});
