import { useCookie, navigateTo } from "#app";
import { useAuthStored } from "~/store/authStored";
// eslint-disable-next-line no-undef
export default defineNuxtRouteMiddleware((to) => {
  const authStored = useAuthStored();

  const token = useCookie("token", {
    sameSite: true
  }); // get token from cookies
  const sessionId = useCookie("session_id", {
    sameSite: true
  });

  if (sessionId.value && token.value) {
    // check if value exists
    authStored.authenticated = true; // update the state to authenticated
  } else {
    sessionId.value = null;
    token.value = null;
    authStored.authenticated = false;
  }

  console.log("INIZIO MIDDLEWARE", sessionId.value, token.value);
  if (sessionId.value && to?.fullPath === "/" && token.value) {
    if (authStored.ruoli.includes("logistica")) {
      return navigateTo("/movimenti");
    }
    if (authStored.ruoli.includes("fornitore")) {
      return navigateTo("/ordini_acquisto");
    }
  }
});
