import { defineStore } from "pinia";
import { $fetch } from "ofetch";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading, messageError } = useGetData();
export const useNodiStore = defineStore("nodiStore", {
  state: () => ({
    nodi: data,
    nodiLogstiche: [],
    caricandoNodi: loading,
    errorMessage: messageError
  }),
  actions: {
    async getNodiOdoo () {
      await getData("/api/nodi");
    },
    async getNodiLogsticheOdoo () {
      const { data, getData, messageError } = useGetData();
      await getData("/api/nodi/nodi_logistiche");

      if (!messageError.value) {
        this.nodiLogstiche = data;
      }
    }

  }
});
