import { defineStore } from "pinia";
import { useCookie } from "#app";
import { useGetData } from "~/composables/useGetData";
import { usePostData } from "~/composables/usePostData";
import { usePostDataFileStream } from "~/composables/usePostDataFileStream";

const { data, getData, loading, messageError } = useGetData();
export const useTicketAssistenzaStore = defineStore("ticketAssistenzaStore", {
  state: () => ({
    ticketAssistenze: data,
    ticketAssistenzaSelezionato: {},
    caricandoTicketAssistenza: loading,
    errorMessage: messageError,
    fromDate: null,
    toDate: null
  }),
  getters: {
    isTicketAssistenzaSelezionato(state) {
      if (
        !state.ticketAssistenzaSelezionato ||
        Object.keys(state.ticketAssistenzaSelezionato).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  actions: {
    async getTicketAssistenzaOdoo() {
      await getData("/api/ticket_assistenza");
    },
    resetTicketAssistenzaSelezionato() {
      this.ticketAssistenzaSelezionato = [];
    },
    async aggiornaStato(ticket) {
      const { data, postData, loading, messageError } = usePostData();
      this.caricandoTicketAssistenza = true;
      await postData("/api/ticket_assistenza/aggiorna_stato", ticket);

      if (!messageError.value) {
        this.aggiornareElemento(data.value.ticket_assistenza);
      } else {
        this.errorMessage = messageError;
      }

      this.caricandoTicketAssistenza = false;
    },
    aggiornareElemento(data) {
      const index = this.ticketAssistenze.findIndex(({ id }) => id === data.id);
      Object.assign(this.ticketAssistenze[index], data);
      this.ticketAssistenzaSelezionato = data;
    },
    async downloadFileOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/ticket_assistenza/download_file", body);
      return data.value;
    },
    async uploadFileOdoo(body) {
      const { data, postData, messageError } = usePostData();
      console.log("uploadFileOdoo ->", JSON.stringify(body));
      await postData("/api/ticket_assistenza/upload_file", body);
      if (!messageError.value) {
        this.ticketAssistenzaSelezionato = data.value.ticket_assistenza;
      } else {
        this.errorMessage = messageError;
      }
    },
    async uploadNotaLogistica(body) {
      const { data, postData, messageError } = usePostData();
      const username = useCookie("usernameLogged", { sameSite: true });
      body.username = username.value;
      await postData("/api/ticket_assistenza/note_logistica", body);
      if (!messageError.value) {
        this.ticketAssistenzaSelezionato = data.value.ticket_assistenza;
      } else {
        this.errorMessage = messageError;
      }
    },
    async stampaModuloOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/ticket_assistenza/stampa_modulo", body);
      return data.value;
    },
    async stampaOrdineVenditaTicket(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/stampa_ordine_vendita", body);
      return data.value;
    }
  }
});
