import { default as indexEVO0obIu0nMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/index.vue?macro=true";
import { default as login4BhIFMIjXsMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/login.vue?macro=true";
import { default as arrivatiW6g7W5M5j3Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivati.vue?macro=true";
import { default as arrivoOkEKMhLnriMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivo.vue?macro=true";
import { default as incompletiULona4si1YMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/incompleti.vue?macro=true";
import { default as pronte_consegna9NCTvMffKAMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/pronte_consegna.vue?macro=true";
import { default as _91id_93hMmBebC1asMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/[id].vue?macro=true";
import { default as createnrKfmyA4XKMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/create.vue?macro=true";
import { default as indexYWpwYBEDcaMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/index.vue?macro=true";
import { default as ingressiBNIx1G8GtPMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/ingressi.vue?macro=true";
import { default as _91id_939BhO23tSEzMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/update/[id].vue?macro=true";
import { default as uscitehdVJW7WINUMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/uscite.vue?macro=true";
import { default as _91id_93iEyXHxGV3EMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/[id].vue?macro=true";
import { default as da_prendere_in_caricofoKVJ6bowxMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/da_prendere_in_carico.vue?macro=true";
import { default as indexU1uyLBRXFaMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/index.vue?macro=true";
import { default as presi_in_carico5BLCLxOl0VMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/presi_in_carico.vue?macro=true";
import { default as incompleti1ZxKOc0IPGMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/incompleti.vue?macro=true";
import { default as indexWeapeYJNqMMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/index.vue?macro=true";
import { default as pronte_consegnaCJHDOdQckwMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/pronte_consegna.vue?macro=true";
import { default as _91id_93ufoVE0uZ9TMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/[id].vue?macro=true";
import { default as indextWoluhSnr7Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/index.vue?macro=true";
import { default as _91id_93Q5Q4Nuo2G7Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/[id].vue?macro=true";
import { default as consegne_in_corsoQTcwR2kXJuMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_in_corso.vue?macro=true";
import { default as consegne_programmatat0j8Wtj8BtMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_programmata.vue?macro=true";
import { default as indexC3DL0W4lsDMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/index.vue?macro=true";
export default [
  {
    name: indexEVO0obIu0nMeta?.name ?? "index",
    path: indexEVO0obIu0nMeta?.path ?? "/",
    meta: indexEVO0obIu0nMeta || {},
    alias: indexEVO0obIu0nMeta?.alias || [],
    redirect: indexEVO0obIu0nMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4BhIFMIjXsMeta?.name ?? "login",
    path: login4BhIFMIjXsMeta?.path ?? "/login",
    meta: login4BhIFMIjXsMeta || {},
    alias: login4BhIFMIjXsMeta?.alias || [],
    redirect: login4BhIFMIjXsMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/login.vue").then(m => m.default || m)
  },
  {
    name: arrivatiW6g7W5M5j3Meta?.name ?? "merci-arrivati",
    path: arrivatiW6g7W5M5j3Meta?.path ?? "/merci/arrivati",
    meta: arrivatiW6g7W5M5j3Meta || {},
    alias: arrivatiW6g7W5M5j3Meta?.alias || [],
    redirect: arrivatiW6g7W5M5j3Meta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivati.vue").then(m => m.default || m)
  },
  {
    name: arrivoOkEKMhLnriMeta?.name ?? "merci-arrivo",
    path: arrivoOkEKMhLnriMeta?.path ?? "/merci/arrivo",
    meta: arrivoOkEKMhLnriMeta || {},
    alias: arrivoOkEKMhLnriMeta?.alias || [],
    redirect: arrivoOkEKMhLnriMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivo.vue").then(m => m.default || m)
  },
  {
    name: incompletiULona4si1YMeta?.name ?? "merci-incompleti",
    path: incompletiULona4si1YMeta?.path ?? "/merci/incompleti",
    meta: incompletiULona4si1YMeta || {},
    alias: incompletiULona4si1YMeta?.alias || [],
    redirect: incompletiULona4si1YMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/incompleti.vue").then(m => m.default || m)
  },
  {
    name: pronte_consegna9NCTvMffKAMeta?.name ?? "merci-pronte_consegna",
    path: pronte_consegna9NCTvMffKAMeta?.path ?? "/merci/pronte_consegna",
    meta: pronte_consegna9NCTvMffKAMeta || {},
    alias: pronte_consegna9NCTvMffKAMeta?.alias || [],
    redirect: pronte_consegna9NCTvMffKAMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/pronte_consegna.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hMmBebC1asMeta?.name ?? "movimenti-id",
    path: _91id_93hMmBebC1asMeta?.path ?? "/movimenti/:id()",
    meta: _91id_93hMmBebC1asMeta || {},
    alias: _91id_93hMmBebC1asMeta?.alias || [],
    redirect: _91id_93hMmBebC1asMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/[id].vue").then(m => m.default || m)
  },
  {
    name: createnrKfmyA4XKMeta?.name ?? "movimenti-create",
    path: createnrKfmyA4XKMeta?.path ?? "/movimenti/create",
    meta: createnrKfmyA4XKMeta || {},
    alias: createnrKfmyA4XKMeta?.alias || [],
    redirect: createnrKfmyA4XKMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/create.vue").then(m => m.default || m)
  },
  {
    name: indexYWpwYBEDcaMeta?.name ?? "movimenti",
    path: indexYWpwYBEDcaMeta?.path ?? "/movimenti",
    meta: indexYWpwYBEDcaMeta || {},
    alias: indexYWpwYBEDcaMeta?.alias || [],
    redirect: indexYWpwYBEDcaMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/index.vue").then(m => m.default || m)
  },
  {
    name: ingressiBNIx1G8GtPMeta?.name ?? "movimenti-ingressi",
    path: ingressiBNIx1G8GtPMeta?.path ?? "/movimenti/ingressi",
    meta: ingressiBNIx1G8GtPMeta || {},
    alias: ingressiBNIx1G8GtPMeta?.alias || [],
    redirect: ingressiBNIx1G8GtPMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/ingressi.vue").then(m => m.default || m)
  },
  {
    name: _91id_939BhO23tSEzMeta?.name ?? "movimenti-update-id",
    path: _91id_939BhO23tSEzMeta?.path ?? "/movimenti/update/:id()",
    meta: _91id_939BhO23tSEzMeta || {},
    alias: _91id_939BhO23tSEzMeta?.alias || [],
    redirect: _91id_939BhO23tSEzMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/update/[id].vue").then(m => m.default || m)
  },
  {
    name: uscitehdVJW7WINUMeta?.name ?? "movimenti-uscite",
    path: uscitehdVJW7WINUMeta?.path ?? "/movimenti/uscite",
    meta: uscitehdVJW7WINUMeta || {},
    alias: uscitehdVJW7WINUMeta?.alias || [],
    redirect: uscitehdVJW7WINUMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/uscite.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iEyXHxGV3EMeta?.name ?? "ordini_acquisto-id",
    path: _91id_93iEyXHxGV3EMeta?.path ?? "/ordini_acquisto/:id()",
    meta: _91id_93iEyXHxGV3EMeta || {},
    alias: _91id_93iEyXHxGV3EMeta?.alias || [],
    redirect: _91id_93iEyXHxGV3EMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/[id].vue").then(m => m.default || m)
  },
  {
    name: da_prendere_in_caricofoKVJ6bowxMeta?.name ?? "ordini_acquisto-da_prendere_in_carico",
    path: da_prendere_in_caricofoKVJ6bowxMeta?.path ?? "/ordini_acquisto/da_prendere_in_carico",
    meta: da_prendere_in_caricofoKVJ6bowxMeta || {},
    alias: da_prendere_in_caricofoKVJ6bowxMeta?.alias || [],
    redirect: da_prendere_in_caricofoKVJ6bowxMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/da_prendere_in_carico.vue").then(m => m.default || m)
  },
  {
    name: indexU1uyLBRXFaMeta?.name ?? "ordini_acquisto",
    path: indexU1uyLBRXFaMeta?.path ?? "/ordini_acquisto",
    meta: indexU1uyLBRXFaMeta || {},
    alias: indexU1uyLBRXFaMeta?.alias || [],
    redirect: indexU1uyLBRXFaMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/index.vue").then(m => m.default || m)
  },
  {
    name: presi_in_carico5BLCLxOl0VMeta?.name ?? "ordini_acquisto-presi_in_carico",
    path: presi_in_carico5BLCLxOl0VMeta?.path ?? "/ordini_acquisto/presi_in_carico",
    meta: presi_in_carico5BLCLxOl0VMeta || {},
    alias: presi_in_carico5BLCLxOl0VMeta?.alias || [],
    redirect: presi_in_carico5BLCLxOl0VMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/presi_in_carico.vue").then(m => m.default || m)
  },
  {
    name: incompleti1ZxKOc0IPGMeta?.name ?? "ordini_vendita-incompleti",
    path: incompleti1ZxKOc0IPGMeta?.path ?? "/ordini_vendita/incompleti",
    meta: incompleti1ZxKOc0IPGMeta || {},
    alias: incompleti1ZxKOc0IPGMeta?.alias || [],
    redirect: incompleti1ZxKOc0IPGMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/incompleti.vue").then(m => m.default || m)
  },
  {
    name: indexWeapeYJNqMMeta?.name ?? "ordini_vendita",
    path: indexWeapeYJNqMMeta?.path ?? "/ordini_vendita",
    meta: indexWeapeYJNqMMeta || {},
    alias: indexWeapeYJNqMMeta?.alias || [],
    redirect: indexWeapeYJNqMMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/index.vue").then(m => m.default || m)
  },
  {
    name: pronte_consegnaCJHDOdQckwMeta?.name ?? "ordini_vendita-pronte_consegna",
    path: pronte_consegnaCJHDOdQckwMeta?.path ?? "/ordini_vendita/pronte_consegna",
    meta: pronte_consegnaCJHDOdQckwMeta || {},
    alias: pronte_consegnaCJHDOdQckwMeta?.alias || [],
    redirect: pronte_consegnaCJHDOdQckwMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/pronte_consegna.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ufoVE0uZ9TMeta?.name ?? "ticket_assistenza-id",
    path: _91id_93ufoVE0uZ9TMeta?.path ?? "/ticket_assistenza/:id()",
    meta: _91id_93ufoVE0uZ9TMeta || {},
    alias: _91id_93ufoVE0uZ9TMeta?.alias || [],
    redirect: _91id_93ufoVE0uZ9TMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/[id].vue").then(m => m.default || m)
  },
  {
    name: indextWoluhSnr7Meta?.name ?? "ticket_assistenza",
    path: indextWoluhSnr7Meta?.path ?? "/ticket_assistenza",
    meta: indextWoluhSnr7Meta || {},
    alias: indextWoluhSnr7Meta?.alias || [],
    redirect: indextWoluhSnr7Meta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Q5Q4Nuo2G7Meta?.name ?? "uscite_consegna-id",
    path: _91id_93Q5Q4Nuo2G7Meta?.path ?? "/uscite_consegna/:id()",
    meta: _91id_93Q5Q4Nuo2G7Meta || {},
    alias: _91id_93Q5Q4Nuo2G7Meta?.alias || [],
    redirect: _91id_93Q5Q4Nuo2G7Meta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/[id].vue").then(m => m.default || m)
  },
  {
    name: consegne_in_corsoQTcwR2kXJuMeta?.name ?? "uscite_consegna-consegne_in_corso",
    path: consegne_in_corsoQTcwR2kXJuMeta?.path ?? "/uscite_consegna/consegne_in_corso",
    meta: consegne_in_corsoQTcwR2kXJuMeta || {},
    alias: consegne_in_corsoQTcwR2kXJuMeta?.alias || [],
    redirect: consegne_in_corsoQTcwR2kXJuMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_in_corso.vue").then(m => m.default || m)
  },
  {
    name: consegne_programmatat0j8Wtj8BtMeta?.name ?? "uscite_consegna-consegne_programmata",
    path: consegne_programmatat0j8Wtj8BtMeta?.path ?? "/uscite_consegna/consegne_programmata",
    meta: consegne_programmatat0j8Wtj8BtMeta || {},
    alias: consegne_programmatat0j8Wtj8BtMeta?.alias || [],
    redirect: consegne_programmatat0j8Wtj8BtMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_programmata.vue").then(m => m.default || m)
  },
  {
    name: indexC3DL0W4lsDMeta?.name ?? "uscite_consegna",
    path: indexC3DL0W4lsDMeta?.path ?? "/uscite_consegna",
    meta: indexC3DL0W4lsDMeta || {},
    alias: indexC3DL0W4lsDMeta?.alias || [],
    redirect: indexC3DL0W4lsDMeta?.redirect || undefined,
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/index.vue").then(m => m.default || m)
  }
]