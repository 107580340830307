import { defineStore } from "pinia";
import { visible } from "ansi-colors";

export const useErrorMessageStored = defineStore("useErrorMessageStored", {
  state: () => ({
    messaggio: null,
    titolo: "Error",
    visibleErrorMessage: false
  }),
  actions: {
    showError () {
      this.visibleErrorMessage = true;
    },
    hideError () {
      this.visibleErrorMessage = false;
    },
    clearError () {
      this.visibleErrorMessage = false;
      this.titolo = "Error";
      this.messaggio = null;
    }
  }
});
