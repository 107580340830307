
import { updateAppConfig } from '#app'
import { defuFn } from '/home/johan/VUE-PROJECTS/portaleFornitori/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "2b0fa57c-01f7-4b01-8757-71be1dd464b5"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
