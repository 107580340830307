import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading, messageError } = useGetData();
export const useMerciInArrivoStore = defineStore("merciInArrivoStore", {
  state: () => ({
    merciInArrivo: data,
    caricandoMerci: loading,
    errorMessage: messageError
  }),
  actions: {
    async getMerciInArrivoOdoo () {
      await getData("/api/merci/arrivo");
    }
  }
});
