import { ref } from "vue";
import { format } from "date-fns";
import { useMovimentiStore } from "~/store/movimentiStored";
import { useColliStore } from "~/store/colliStored";
import { useNodiStore } from "~/store/nodiStored";
import { useProdottiStoccabiliStore } from "~/store/prodottiStoccabiliStored";
import { useUsciteConsegnaStore } from "~/store/usciteConsegnaStored";
import { useSquadreStore } from "~/store/squadreStored";
import { useOrdiniAcquistoStore } from "~/store/ordiniAcquistoStored";
import { useTicketAssistenzaStore } from "~/store/ticketAssistenzaStored";
import { useAuthStored } from "~/store/authStored";
import { useMerciArrivatiStore } from "~/store/merciArrivatiStored";
import { useMerciInArrivoStore } from "~/store/merciInArrivoStored";
import { useMerciPronteConsegnaStore } from "~/store/merciPronteConsegnaStored";
import { useOrdiniVenditaStore } from "~/store/ordiniVenditaStored";
import { useDataFiltersStore } from "~/store/dataFiltersStored";
export const useSvuotaTuttiStored = () => {
  const movimentiStore = useMovimentiStore();
  const colliStore = useColliStore();
  const nodiStore = useNodiStore();
  const stoccabiliStore = useProdottiStoccabiliStore();
  const usciteConsegnaStore = useUsciteConsegnaStore();
  const squadreStore = useSquadreStore();
  const ordiniAcquistoStore = useOrdiniAcquistoStore();
  const ticketAssistenzaStore = useTicketAssistenzaStore();
  const authStored = useAuthStored();
  const merciArrivatiStore = useMerciArrivatiStore();
  const merciInArrivoStore = useMerciInArrivoStore();
  const merciPronteConsegnaStore = useMerciPronteConsegnaStore();
  const ordiniVenditaStore = useOrdiniVenditaStore();
  const prodottiStoccabiliStore = useProdottiStoccabiliStore();
  const dataFiltersStore = useDataFiltersStore();

  const resetAllData = () => {
    movimentiStore.movimenti = [];
    colliStore.colli = [];
    nodiStore.nodi = [];
    stoccabiliStore.prodottiStoccabili = [];
    usciteConsegnaStore.usciteConsegna = [];
    squadreStore.squadre = [];
    ordiniAcquistoStore.ordiniAcquisto = [];
    ordiniAcquistoStore.fromDate = null;
    ordiniAcquistoStore.toDate = null;
    ticketAssistenzaStore.ticketAssistenze = [];
    authStored.ruoli = [];
    merciArrivatiStore.merciArrivati = [];
    merciInArrivoStore.merciInArrivo = [];
    merciPronteConsegnaStore.merciPronteConsegna = [];
    ordiniVenditaStore.ordiniVendita = [];
    prodottiStoccabiliStore.prodottiStoccabili = [];
    dataFiltersStore.fromDate = null;
    dataFiltersStore.toDate = null;
  };

  return {
    resetAllData
  };
};
