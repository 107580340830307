import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAsyncData } from "#build/imports";
import { useErrorMessageStored } from "~/store/errorMessageStored";

export const usePostDataFileStream = () => {
  const data = ref([]);
  const filename = ref([]);
  const loading = ref(true);
  const messageError = ref(null);
  const postDataStream = async (url, body) => {
    try {
      loading.value = true;
      const response = await fetch(url, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
        body: JSON.stringify(body),
        method: "Post"
      });
      if (response.status > 300) {
        const jsonBody = await response.json();

        if (!jsonBody.data.success) {
          throw new Error(JSON.stringify(jsonBody.data.message));
        }

        if ("error" in jsonBody) {
          throw new Error(JSON.stringify(jsonBody.error.data.message));
        } else {
          throw new Error(JSON.stringify(jsonBody));
        }
      }
      const bodyBlob = await response.blob();
      console.log(response.headers.get("Content-Disposition"));
      data.value = bodyBlob;
      console.log(data.value);
    } catch (error) {
      messageError.value = error;
      const storeError = useErrorMessageStored();
      // const router = useRouter();
      if (error.message.toLowerCase().includes("session expired")) {
        storeError.messaggio = "Sessione scaduta. Riprova a fare il login";
        storeError.showError();
      } else {
        storeError.messaggio = error.toString();
        storeError.showError();
      }
    } finally {
      loading.value = false;
    }
    // return {
    //   data, loading, messageError
    // };
  };
  return {
    postDataStream,
    data,
    loading,
    messageError
  };
};
