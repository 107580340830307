// TUTORIAL -> https://dev.to/rafaelmagalhaes/authentication-in-nuxt-3-375o

import { useCookie, navigateTo } from "#app";
import { useAuthStored } from "~/store/authStored";
// eslint-disable-next-line no-undef
export default defineNuxtRouteMiddleware(async (to) => {
  const authStored = useAuthStored();
  const svuotaTuttiStored = useSvuotaTuttiStored();

  const token = useCookie("token", {
    sameSite: true
  }); // get token from cookies
  const sessionId = useCookie("session_id", {
    sameSite: true
  });

  if (sessionId.value && token.value) {
    // check if value exists
    authStored.authenticated = true; // update the state to authenticated
  } else {
    sessionId.value = null;
    token.value = null;
    authStored.authenticated = false;
  }

  // se il token esiste però i ruoli sono vuoti, cerco i ruoli.
  // lo faccio qui invece che dentro un plugin perchè quando si viene reindirizzati per la prima volta al path "/"
  // i ruoli sono ancora vuoti, mentre che prima di essere reindirizzati i ruoli devono gia essere compilati.
  // altrimenti non riescono più a vedere nessun menu e dovrebbero aggiornare la pagina
  // per maggiori informazioni: https://nuxt.com/docs/guide/directory-structure/middleware
  if (token.value && authStored.ruoli.length === 0) {
    await authStored.getRuoli();
  }
  console.log("INIZIO MIDDLEWARE", sessionId.value, token.value);
  if (sessionId.value && to.name === "login" && token.value) {
    if (authStored.ruoli.includes("logistica")) {
      return navigateTo("/movimenti");
    }
    if (authStored.ruoli.includes("fornitore")) {
      return navigateTo("/ordini_acquisto");
    }
  }

  if ((!sessionId.value && to?.name !== "login") || !token.value) {
    console.log("NUMERO 2");
    const authDisabled = to.meta.auth === false;
    if (!authDisabled) {
      // eslint-disable-next-line no-undef
      abortNavigation();
      svuotaTuttiStored.resetAllData();

      return navigateTo("/login");
    }
  }

  // if token doesn't exist redirect to log in
  // if (!token.value && to?.name !== "login") {
  //   console.log("NUMERO 2");
  //   const authDisabled = to.meta.auth === false;
  //   if (!authDisabled) {
  //     // eslint-disable-next-line no-undef
  //     abortNavigation();
  //     // eslint-disable-next-line no-undef
  //     return navigateTo("/login");
  //   }
  // }
});
