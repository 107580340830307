import { defineStore } from "pinia";
import { $fetch } from "ofetch";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading } = useGetData();
export const useColliStore = defineStore("colliStore", {
  state: () => ({
    colli: data,
    caricandoColli: loading
  }),
  actions: {
    async getColliOdoo () {
      await getData("/api/colli");
    }
  }
});
