import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading, messageError } = useGetData();
export const useMerciArrivatiStore = defineStore("merciArrivatiStore", {
  state: () => ({
    merciArrivati: data,
    caricandoMerciArrivati: loading,
    errorMessage: messageError
  }),
  actions: {
    async getMerciArrivatiOdoo () {
      await getData("/api/merci/arrivati");
    }
  }
});
