// TUTORIAL -> https://dev.to/rafaelmagalhaes/authentication-in-nuxt-3-375o

import { useCookie } from "#app";
import { useAuthStored } from "~/store/authStored";
// eslint-disable-next-line no-undef
export default defineNuxtRouteMiddleware((to) => {
  // Ho creato questo secondo middleware per risolvere un problema:
  // dentro un middleware non si può inserire await, il quale avevo bisogno per aspettare di ricevere i ruoli
  // dell'utente per poi fare le richieste di dati a Odoo. Un'altenativa è creare questo secondo middleware. Come funzione
  // l'esecuzione dei middleware globali? esegue un middleware per volta, quando finisce uno, inizia l'altro. Per gestire l'ordine
  // in cui vengono eseguiti, si deve aggiungere sul filename un numero davanti.
  const { getAllData } = useGetNecessaryData();
  const authStored = useAuthStored();

  const token = useCookie("token"); // get token from cookies
  if (token.value && authStored.ruoli.length > 0) {
    getAllData();
  }
});
