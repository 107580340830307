import { defineStore } from "pinia";
import { tryCatch } from "standard-as-callback/built/utils";
import { useGetData } from "~/composables/useGetData";
import { usePostData } from "~/composables/usePostData";
import { usePostDataFileStream } from "~/composables/usePostDataFileStream";

const { data, getData, loading, messageError } = useGetData();
export const useUsciteConsegnaStore = defineStore("usciteConsegnaStore", {
  state: () => ({
    usciteConsegna: data,
    uscitaConsegnaSelezionato: {},
    caricandoUsciteConsegna: loading,
    errorMessageUscite: messageError,
    fromDataPattuitaFiltro: null,
    toDataPattuitaFiltro: null,
    fromDataRealeFiltro: null,
    toDataRealeFiltro: null,
    rifOrdineFiltro: null
  }),
  getters: {
    isUscitaConsegnaSelezionato(state) {
      if (
        !state.uscitaConsegnaSelezionato ||
        Object.keys(state.uscitaConsegnaSelezionato).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  actions: {
    async getUsciteConsegnaOdoo() {
      await getData("/api/uscite_consegna");
    },
    async createUscitaConsegnaOdoo(uscitaConsegna) {
      // azzero l'errore
      this.errorMessageUscite = null;
      const { data, postData, loading, messageError } = usePostData();
      this.caricandoUsciteConsegna = loading.value;
      await postData("/api/uscite_consegna", uscitaConsegna);
      if (!messageError.value) {
        const usciteIds = data.value.uscite_consegna;
        for (const uscita of usciteIds) {
          this.usciteConsegna.push(uscita);
        }
      } else {
        this.errorMessageUscite = messageError;
      }
      this.caricandoUsciteConsegna = loading.value;
    },
    resetUscitaConsegnaSelezionato() {
      this.uscitaConsegnaSelezionato = [];
    },
    async updateUsciteConsegnaOdoo(body) {
      const { data, postData, loading, messageError } = usePostData();
      this.caricandoUsciteConsegna = loading.value;
      await postData("/api/uscite_consegna/update", body);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
      } else {
        this.errorMessage = messageError;
      }
      this.caricandoUsciteConsegna = loading.value;
    },
    async deleteUscitaConsegnaOdoo(uscita) {
      const { postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/delete", uscita);
      if (!messageError.value) {
        const item = this.usciteConsegna.filter((item) => {
          return item.id === parseInt(uscita.id);
        })[0];
        const index = this.usciteConsegna.indexOf(item);
        this.usciteConsegna.splice(index, 1);
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async setUscitaAnnullata(uscita) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/annulla", uscita);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async setUscitaFallita(uscita) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/fallita", uscita);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async setUscitaInCorso(uscita) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/in_corso", uscita);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async setUscitaRiuscita(uscita) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/riuscita", uscita);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    aggiornareElemento(data) {
      // const index = this.usciteConsegna.findIndex(({ id }) => id === this.uscitaConsegnaSelezionato.id);
      // this.usciteConsegna.splice(index, 1);
      // this.usciteConsegna.push(data);
      // this.uscitaConsegnaSelezionato = data;
      const index = this.usciteConsegna.findIndex(({ id }) => id === data.id);
      Object.assign(this.usciteConsegna[index], data);
    },
    async searchUsciteConsegnaFiltrati(filtri: Array<Object>) {
      this.caricandoUsciteConsegna = true;
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/uscite_consegna/cerca_uscite_consegna", filtri);
      if (!messageError.value) {
        this.usciteConsegna = data.value.result;
        this.caricandoUsciteConsegna = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async creaDDTOdoo(body) {
      const { data, postData, messageError } = usePostData();
      await postData("/api/uscite_consegna/crea_ddt", body);
      if (!messageError.value) {
        this.uscitaConsegnaSelezionato.ddt = data.value.ddt;
        this.uscitaConsegnaSelezionato.data_spedizione =
          data.value.data_spedizione;
      } else {
        this.errorMessage = messageError;
      }
      return data.value;
    },
    async stampaDDTOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/uscite_consegna/stampa_ddt", body);
      return data.value;
    },
    async stampaBorderoOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/uscite_consegna/stampa_bordero", body);
      return data.value;
    },
    async stampaFatturaOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/uscite_consegna/stampa_fattura", body);
      return data.value;
    },
    async aggiornaNotaOdoo(body) {
      const { data, postData, messageError } = usePostData();
      await postData("/api/uscite_consegna/aggiorna_nota", body);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.uscite_consegna);
      } else {
        this.errorMessage = messageError;
      }
    }
  }
});
