import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";
import { usePostData } from "~/composables/usePostData";

const { data, getData, loading, messageError } = useGetData();
export const useMerciPronteConsegnaStore = defineStore(
  "merciPronteConsegnaStore",
  {
    state: () => ({
      merciPronteConsegna: data,
      caricandoMerciPronteConsegna: loading,
      errorMessage: messageError,
      caricandoDataConsegna: false
    }),
    actions: {
      async getMerciPronteConsegnaOdoo() {
        await getData("/api/merci/pronte_consegna");
      },
      async aggiungiDataConsegnaOdoo(body) {
        const {
          data: result,
          postData,
          messageError: messageErrorPost
        } = usePostData();
        await postData("/api/merci/aggiungi_data_consegna", body);

        if (!messageErrorPost.value) {
          this.aggiornareElemento(result.value.merci);
          this.caricandoDataConsegna = false;
        }

        this.caricandoDataConsegna = false;
      },
      aggiornareElemento(data) {
        for (const merce of data) {
          console.log("merce ", merce);
          const index = this.merciPronteConsegna.findIndex(
            ({ id }) => id === merce.id
          );
          Object.assign(this.merciPronteConsegna[index], merce);
        }
      }
    }
  }
);
