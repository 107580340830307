import { defineStore } from "pinia";
import { $fetch } from "ofetch";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading, messageError } = useGetData();
export const useProdottiStoccabiliStore = defineStore("prodottiStoccabiliStore", {
  state: () => ({
    prodottiStoccabili: data,
    caricandoProdotti: loading,
    errorMessage: messageError
  }),
  actions: {
    async getStoccabiliOdoo () {
      await getData("/api/stoccabili");
    }
  }
});
