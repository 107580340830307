import { ref } from "vue";
import { useAsyncData } from "#build/imports";
import { useErrorMessageStored } from "~/store/errorMessageStored";
export const usePostData = () => {
  const data = ref([]);
  const loading = ref(true);
  const messageError = ref(null);
  const storeError = useErrorMessageStored();

  const postData = async (url, body) => {
    let response = Object;
    let responseText = "";
    try {
      loading.value = true;
      response = await fetch(url, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
        body: JSON.stringify(body),
        method: "Post"
      });
      let jsonBody = null;
      responseText = await response.text();
      const bodyResponse = JSON.parse(responseText);

      // in alcuni casi (es. login) si ottiene una response che dentro è strutturato come un oggetto: response: {body}
      if (bodyResponse.response) {
        jsonBody = bodyResponse.response;
      } else {
        jsonBody = bodyResponse;
      }

      if (response.status > 300) {
        throw new Error(JSON.stringify(jsonBody));
      }
      if ("error" in jsonBody) {
        console.log("error in jsonbody");
        throw new Error(JSON.stringify(jsonBody.error.data.message));
      }

      // gli errori gestiti in modo custom lato odoo, viene restituito dentro jsonBody.result.result
      // e result.success è false
      if (jsonBody.result.success === false) {
        console.log("errore in result success", jsonBody);
        throw new Error(JSON.stringify(jsonBody.result.message));
      }
      data.value = jsonBody.result;
    } catch (error) {
      console.log(url, "errore", error, response);
      // se l'errore è riguardo al json parse
      if (error.toString().includes("JSON")) {
        // se la risposta è unn html e dentro è presente il testo Login, allora vuol dire
        // che manca la token oppure che la sessione è scaduta
        if (responseText.includes("o_login_auth")) {
          messageError.value = "Errore durante il login";
          storeError.messaggio = "Sessione scaduta. Riprova a fare il login";
        } else {
          // altrimenti si tratta di un errore del formato json
          messageError.value = "Formato Json sbagliato";
          storeError.messaggio = "Formato Json sbagliato: " + error.toString();
        }
      } else {
        messageError.value = error;
        storeError.messaggio = error.toString();
      }
    } finally {
      loading.value = false;
      if (storeError.messaggio) {
        console.log("MMOSTRA ERRIRE");
        storeError.showError();
      }
    }
    // return {
    //   data, loading, messageError
    // };
  };
  return {
    postData,
    data,
    loading,
    messageError
  };
};
