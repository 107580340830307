import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";

export const useDatiAusiliariDDT = defineStore("datiAusiliariDDT", {
  state: () => ({
    causaliTrasporto: [],
    condizioniTrasporto: [],
    descrizioniBeni: [],
    metodiTrasporto: [],
    vettori: [],
    caricandoDatiAusiliariDDT: false,
    errorMessage: null
  }),
  getters: {
    isAllDataFilled(state) {
      if (
        state.causaliTrasporto.length > 0 ||
        state.condizioniTrasporto > 0 ||
        state.descrizioniBeni > 0 ||
        state.metodiTrasporto > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  actions: {
    async getDatiAusiliariDDTOdoo() {
      const { data, getData, loading, messageError } = useGetData();
      await getData("/api/dati_ausiliari_ddt");
      console.log("PPPP ", data.value);
      this.causaliTrasporto = data.value.causali_trasporto;
      this.condizioniTrasporto = data.value.condizione_trasporto;
      this.descrizioniBeni = data.value.descrizioni_beni;
      this.metodiTrasporto = data.value.metodo_trasporto;
      this.vettori = data.value.vettori;
    }
  }
});
