import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";
import { usePostDataFileStream } from "~/composables/usePostDataFileStream";

const { data, getData, loading, messageError } = useGetData();
export const useOrdiniVenditaStore = defineStore("ordiniVenditaStore", {
  state: () => ({
    ordiniVendita: data,
    caricandoOrdiniVendita: loading,
    errorMessage: messageError
  }),
  actions: {
    async getOrdiniVenditaOdoo() {
      await getData("/api/ordini_vendita");
    },
    async getOrdiniVenditaIncompletiOdoo() {
      await getData("/api/ordini_vendita/ordini_incompleti");
    },
    async stampaOrdineVenditaOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/stampa_ordine_vendita", body);
      return data.value;
    }
  }
});
