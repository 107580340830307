import { defineStore } from "pinia";
import { useCookie } from "#app";
import { useSvuotaTuttiStored } from "~/composables/useSvuotaTuttiStored";
import { usePostData } from "~/composables/usePostData";
import { useGetData } from "~/composables/useGetData";

interface UserPayloadInterface {
  username: string;
  password: string;
}

export const useAuthStored = defineStore("auth", {
  state: () => ({
    authenticated: false,
    authLoading: false,
    nodi: [],
    ruoli: []
  }),
  getters: {
    isAllDataFilled (state) {
      if (
        state.causaliTrasporto.length > 0 ||
        state.condizioniTrasporto > 0 ||
        state.descrizioniBeni > 0 ||
        state.metodiTrasporto > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAdmin(state) {
      if (state.ruoli.length > 0) {
        if (state.ruoli.includes("admin")) {
          return true;
        }
      }
      return false;
    }
  },
  actions: {
    async authenticateUser ({ username, password }: UserPayloadInterface) {
      try {
        // prima di tutto svuoto gli stored, in caso se per qualche bug o motivo sono pieni
        const { resetAllData } = useSvuotaTuttiStored();
        resetAllData();

        this.authLoading = true;

        // useFetch from nuxt 3
        await this.setSessionId(username).then(async (value) => {
          await this.setTokenId(username, password);
        });
      } catch (error) {
        console.log(error);
        this.authLoading = false;
      }

      // await this.setTokenId(username, password);
    },
    async setSessionId (username) {
      const { data, postData } = usePostData();
      const body = {
        jsonrpc: "2.0",
        params: {
          db: "dsp16produzione",
          login: "utenteportale",
          password: "Lultimochiudalaporta"
        }
      };
      this.authLoading = true;
      await postData("/api/auth", body);
      // this.authLoading = false;

      const cookies = this.parseCookie(data.value.cookies);

      if (data.value) {
        const sessionId = useCookie("session_id", {
          maxAge: 60 * 60 * 24,
          sameSite: true
        });

        sessionId.value = cookies?.session_id; // set session_id to cookie
        this.authenticated = true; // set authenticated  state value to true
        // Creating cookie for username
        const usernameLogged = useCookie("usernameLogged", {
          maxAge: 60 * 60 * 24,
          sameSite: true
        });
        usernameLogged.value = username;
      }
    },
    async setTokenId(username, password) {
      const body = {
        email: username,
        password
      };
      const { data, postData, loading } = usePostData();
      this.authLoading = true;
      await postData("/api/login", body);
      this.authLoading = loading.value;

      if (data.value) {
        const token = useCookie("token", {
          maxAge: 60 * 60 * 7,
          sameSite: true
        }); // useCookie new hook in nuxt 3
        token.value = data?.value?.token; // set token to cookie
      }
    },
    logUserOut() {
      const token = useCookie("token", {
        sameSite: true
      }); // useCookie new hook in nuxt 3
      const sessionId = useCookie("session_id", {
        sameSite: true
      }); // useCookie new hook in nuxt 3
      const usernameLogged = useCookie("usernameLogged", {
        sameSite: true
      });
      this.authenticated = false; // set authenticated  state value to false
      token.value = null; // clear the token cookie
      sessionId.value = null;
      usernameLogged.value = null;
      const { resetAllData } = useSvuotaTuttiStored();
      resetAllData();
    },
    parseCookie(str) {
      return str
        .split(";")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
          // se il cookie ha due elementi esempio  session_id=4b60cd6effa07d54fbc042d1f989fbaa7bb38b7b;
          // creo l'oggetto in questo modo
          if (v.length > 1) {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
              v[1].trim()
            );
          } else {
            // altrimenti il cookie è tipo  "HttpOnly;"
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
              v[0].trim()
            );
          }
          return acc;
        }, {});
    },
    async getRuoli() {
      const { data, getData, loading } = useGetData();
      this.authLoading = true;
      await getData("/api/ruoli");
      this.ruoli = data.value;
      this.authLoading = loading.value;
    }
  }
});
